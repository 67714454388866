/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"fotoelba"}>
        <Column className="--menu pb--08 pt--08" style={{"backgroundColor":"rgba(255,255,255,1)"}} menu={true}>
          
          <Menu className="--right" style={{"maxWidth":1310}} fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box --center" style={{"maxWidth":310}}>
              
              <Image style={{"maxWidth":142}} alt={""} src={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png 860w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=2000x_.png 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style4 --right">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kurzy"} target={""} content={"Kurzy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/zajezdy"} target={""} content={"Zájezdy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/obchod"} target={""} content={"Obchod"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/bazen"} target={""} content={"Bazén"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/klub"} target={""} content={"Klub"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/o-nas"} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/fotogalerie"} target={""} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="--center" name={"8k939hlrmff"} style={{"paddingTop":14,"paddingBottom":15.59375}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left ff--3" style={{"paddingTop":18}} content={"Itálie, ostrov Elba"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"hlj5a9rv14n"} style={{"marginTop":0,"paddingTop":13,"marginBottom":0,"paddingBottom":0}} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/b3e1abc46cc14810a35b5588cc40bdb1_s=860x_.jpeg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/b3e1abc46cc14810a35b5588cc40bdb1_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13078/b3e1abc46cc14810a35b5588cc40bdb1_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13078/b3e1abc46cc14810a35b5588cc40bdb1_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13078/b3e1abc46cc14810a35b5588cc40bdb1_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/13078/b3e1abc46cc14810a35b5588cc40bdb1_s=2000x_.jpeg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/af3993908f34415fb2aa18a9b06de93d_s=860x_.jpeg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/af3993908f34415fb2aa18a9b06de93d_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13078/af3993908f34415fb2aa18a9b06de93d_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13078/af3993908f34415fb2aa18a9b06de93d_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13078/af3993908f34415fb2aa18a9b06de93d_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/13078/af3993908f34415fb2aa18a9b06de93d_s=2000x_.jpeg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/1b2fe6e80a054cde9870c35477a3b483_s=860x_.jpeg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/1b2fe6e80a054cde9870c35477a3b483_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13078/1b2fe6e80a054cde9870c35477a3b483_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13078/1b2fe6e80a054cde9870c35477a3b483_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13078/1b2fe6e80a054cde9870c35477a3b483_s=1400x_.jpeg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/71505ce5b2494502bf9aece82fc75f61_s=660x_.jpeg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/71505ce5b2494502bf9aece82fc75f61_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13078/71505ce5b2494502bf9aece82fc75f61_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13078/71505ce5b2494502bf9aece82fc75f61_s=860x_.jpeg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/c5a0db49f36348d59f6fbebbfbad69b4_s=860x_.jpeg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/c5a0db49f36348d59f6fbebbfbad69b4_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13078/c5a0db49f36348d59f6fbebbfbad69b4_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13078/c5a0db49f36348d59f6fbebbfbad69b4_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13078/c5a0db49f36348d59f6fbebbfbad69b4_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/13078/c5a0db49f36348d59f6fbebbfbad69b4_s=2000x_.jpeg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/59172b49dcb9420eac2232a94c6ea7c5_s=860x_.jpeg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/59172b49dcb9420eac2232a94c6ea7c5_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13078/59172b49dcb9420eac2232a94c6ea7c5_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13078/59172b49dcb9420eac2232a94c6ea7c5_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13078/59172b49dcb9420eac2232a94c6ea7c5_s=1400x_.jpeg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/cb9d0915c10c46ae8e55f9f41436f4d4_s=860x_.jpeg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/cb9d0915c10c46ae8e55f9f41436f4d4_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13078/cb9d0915c10c46ae8e55f9f41436f4d4_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13078/cb9d0915c10c46ae8e55f9f41436f4d4_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13078/cb9d0915c10c46ae8e55f9f41436f4d4_s=1400x_.jpeg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/ddc24670d2524785a68688e4305e2385_s=860x_.jpeg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/ddc24670d2524785a68688e4305e2385_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13078/ddc24670d2524785a68688e4305e2385_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13078/ddc24670d2524785a68688e4305e2385_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13078/ddc24670d2524785a68688e4305e2385_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/13078/ddc24670d2524785a68688e4305e2385_s=2000x_.jpeg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/2deb8e4b14634d00923137e242d844ac_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/2deb8e4b14634d00923137e242d844ac_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/2deb8e4b14634d00923137e242d844ac_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/2deb8e4b14634d00923137e242d844ac_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/1a054e90636c469e89ed0e70812041e8_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/1a054e90636c469e89ed0e70812041e8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/1a054e90636c469e89ed0e70812041e8_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/080437b317504172b2cd60d08a2d7649_s=350x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/080437b317504172b2cd60d08a2d7649_s=350x_.jpg 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/e1496e2043f64f90aa20ffbedf18cd7d_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/e1496e2043f64f90aa20ffbedf18cd7d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/e1496e2043f64f90aa20ffbedf18cd7d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/e1496e2043f64f90aa20ffbedf18cd7d_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":16.5703125}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/e3fd0d4e20ad4c4996ecb07f79ba4533_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/e3fd0d4e20ad4c4996ecb07f79ba4533_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/e3fd0d4e20ad4c4996ecb07f79ba4533_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/e3fd0d4e20ad4c4996ecb07f79ba4533_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/e81363315b9642a589d2f92398ac1c5a_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/e81363315b9642a589d2f92398ac1c5a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/e81363315b9642a589d2f92398ac1c5a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/e81363315b9642a589d2f92398ac1c5a_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/73a6deecf24642829d4bef1d568b606d_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/73a6deecf24642829d4bef1d568b606d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/73a6deecf24642829d4bef1d568b606d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/73a6deecf24642829d4bef1d568b606d_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/d065540559f04661ba8c680534b5edc3_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/d065540559f04661ba8c680534b5edc3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/d065540559f04661ba8c680534b5edc3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/d065540559f04661ba8c680534b5edc3_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":18.2421875}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/eee5aa171fc64c78b689d86ff389bd8d_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/eee5aa171fc64c78b689d86ff389bd8d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/eee5aa171fc64c78b689d86ff389bd8d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/eee5aa171fc64c78b689d86ff389bd8d_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/31699fedb3a742719233edf38df5cdfd_s=350x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/31699fedb3a742719233edf38df5cdfd_s=350x_.jpg 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/3de89be58ead4b3ea5c05b5be0891261_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/3de89be58ead4b3ea5c05b5be0891261_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/3de89be58ead4b3ea5c05b5be0891261_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/3de89be58ead4b3ea5c05b5be0891261_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/327b69133824444784b39127b5093065_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/327b69133824444784b39127b5093065_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/327b69133824444784b39127b5093065_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/327b69133824444784b39127b5093065_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":18.2421875}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/2815dd60ef984e4c9501d7b808f02f46_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/2815dd60ef984e4c9501d7b808f02f46_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/2815dd60ef984e4c9501d7b808f02f46_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/2815dd60ef984e4c9501d7b808f02f46_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/dfcd1397ad9845d4b0c75a468e6984eb_s=350x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/dfcd1397ad9845d4b0c75a468e6984eb_s=350x_.png 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/154ab3e9263e4dd5bb78e137927fd706_s=350x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/154ab3e9263e4dd5bb78e137927fd706_s=350x_.jpg 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/52ea65b267da485089f483bb63df156a_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/52ea65b267da485089f483bb63df156a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/52ea65b267da485089f483bb63df156a_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":18.2421875}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/c0cd20030e914d77b39ef6ed1bf8c76e_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/c0cd20030e914d77b39ef6ed1bf8c76e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/c0cd20030e914d77b39ef6ed1bf8c76e_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/296098dc9ff7493f892d01bf5b03318c_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/296098dc9ff7493f892d01bf5b03318c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/296098dc9ff7493f892d01bf5b03318c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/296098dc9ff7493f892d01bf5b03318c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/296098dc9ff7493f892d01bf5b03318c_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/e8f470c01540495e8e71951f89138e3d_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/e8f470c01540495e8e71951f89138e3d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/e8f470c01540495e8e71951f89138e3d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/e8f470c01540495e8e71951f89138e3d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/e8f470c01540495e8e71951f89138e3d_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/e8f470c01540495e8e71951f89138e3d_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/84ba0c3bcdec412a96887605dd5bc5c4_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/84ba0c3bcdec412a96887605dd5bc5c4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/84ba0c3bcdec412a96887605dd5bc5c4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/84ba0c3bcdec412a96887605dd5bc5c4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/84ba0c3bcdec412a96887605dd5bc5c4_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/84ba0c3bcdec412a96887605dd5bc5c4_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":18.2421875}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/9c9b5c030de34677a27768a21a545fed_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/9c9b5c030de34677a27768a21a545fed_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/9c9b5c030de34677a27768a21a545fed_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/9c9b5c030de34677a27768a21a545fed_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/e28cfb9705bf43c1995c50503bc4d302_s=350x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/e28cfb9705bf43c1995c50503bc4d302_s=350x_.png 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"pnqwyfejtn"} style={{"paddingTop":0,"paddingBottom":9.9609375}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box text-box--left fs--18" style={{"maxWidth":654}} content={"<a href=\"/fotogalerie\">zpět na fotogalerii</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1b4itn5" style={{"marginTop":17,"paddingTop":30,"paddingBottom":7.6171875}} layout={"l1"} css={css`
      
    background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Orca diving, s.r.o.</span>"}>
              </Title>

              <Text className="text-box ff--3 text-box--invert" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"color: rgb(0, 0, 0);\">Ledařská 433/9, Praha 4, 147 00, Česká Republika</span>"}>
              </Text>

              <Image style={{"maxWidth":236,"marginTop":16.6875,"paddingTop":0}} alt={""} src={"https://cdn.swbpg.com/o/13078/000a415e28ce44c5972c4f22d2c183db.PNG"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""} content={null}>
              </Image>

              <Image style={{"maxWidth":36,"marginTop":0,"paddingTop":10,"paddingBottom":0}} alt={""} src={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png"} svg={false} url={"https://www.facebook.com/orcadivingpotapeni"} href={"https://www.facebook.com/orcadivingpotapeni"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png 350w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}